import React from "react";

const QuotesRoutes = [
  {
    path: "/quotes",
    exact: true,
    component: React.lazy(() => import("./index")),
  },
  {
    path: "/quotes/add",
    component: React.lazy(() => import("./AddQuote")),
  },
  {
    path: "/quotes/edit/:id",
    component: React.lazy(() => import("./AddQuote")),
  },
];

export default QuotesRoutes;
