import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import SessionRoutes from "./views/auth/AuthRoutes";
import userRoutes from "./views/users/UserRoutes";
import categoryRoutes from "./views/category/CategoryRoutes";
import subcategoryRoutes from "./views/subcategory/SubCategoryRoutes";
import coursesRoutes from "./views/courses/CoursesRoutes";
import retreatRoutes from "./views/retreats/RetreatRoutes";
import TeacherTrainingRoutes from "./views/retreats/TeacherTrainingRoutes";
import sessionsRoutes from "./views/sessions/SessionsRoutes";
import challengesRoutes from "./views/challenges/ChallengesRoutes";
// import subscriptionsRoutes from "./views/subscriptions/SubscriptionsRoutes";
import suggestionsRoutes from "./views/suggestions/SuggestionsRoutes";
import quotesRoutes from "./views/quote/QuotesRoutes";
import adminRoutes from "./views/admin/AdminRoutes";
import contactsRoutes from "./views/contacts/ContactsRoutes";
import configurationsRoutes from "./views/configurations/ConfigurationsRoutes";
import subscriptionsRoutes from "./views/subscriptions/SubscriptionsRoutes";
import emailLogRoutes from "./views/emailLog/EmailLogRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/analytics" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/404" />,
  },
];

const routes = [
  ...coursesRoutes,
  ...retreatRoutes,
  ...emailLogRoutes,
  ...TeacherTrainingRoutes,
  ...quotesRoutes,
  ...sessionsRoutes,
  ...challengesRoutes,
  ...dashboardRoutes,
  ...categoryRoutes,
  ...subcategoryRoutes,
  ...userRoutes,
  ...adminRoutes,
  ...suggestionsRoutes,
  ...subscriptionsRoutes,
  ...SessionRoutes,
  ...redirectRoute,
  ...configurationsRoutes,
  ...contactsRoutes,
  ...errorRoute,
];

export default routes;
