import "../styles/_app.scss";
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MatxTheme from "./MatxLayout/Theme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";
import { PersistGate } from "redux-persist/lib/integration/react";

import routes from "./RootRoutes";
import storeConfig from "./redux/Store";

import Auth from "./auth/Auth";
import MatxLayout from "./MatxLayout/MatxLayoutSFC";
import AuthGuard from "./auth/AuthGuard";

const App = () => {
  const { store, persistor } = storeConfig;

  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MatxTheme>
            <Auth>
              <Router history={history}>
                <AuthGuard>
                  <MatxLayout />
                </AuthGuard>
              </Router>
            </Auth>
          </MatxTheme>
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
