import {
  GET_ALL_COURSES,
  GET_COURSE_BY_ID,
  REMOVE_COURSE_DETAILS_LOCAL,
  UPDATE_COURSE_STATUS,
  UPDATE_EPISODE_STATUS,
  COURSE_LOADER,
  UPDATE_SEO_SUCCESS,
  UPDATE_COMMISSION_SUCCESS,
  UPDATE_SEO_FAILURE,
} from "../actions/types";

const initialState = {
  coursesData: {},
  courseDetails: { n: 1 },
  loader: { show: false, error: false },
};

const CourseReducer = function (state = initialState, action) {
  switch (action.type) {
    case COURSE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case GET_ALL_COURSES: {
      return {
        ...state,
        coursesData: action.payload,
      };
    }
    case GET_COURSE_BY_ID:
      return {
        ...state,
        courseDetails: action.payload,
      };
    case UPDATE_COMMISSION_SUCCESS:
      console.log(action.payload)
     // let seoData = [...state?.coursesData.courses];
      let ind = state.coursesData?.courses.findIndex(
        (obj) => obj.id === action?.payload?.id
      );
      console.log(ind,"index")
    //  seoData[ind] = action?.payload?.data
     state.coursesData.courses[ind] = action.payload
      return {
        ...state,
        coursesData: {...state.coursesData},
      };
    case REMOVE_COURSE_DETAILS_LOCAL:
      return {
        ...state,
        courseDetails: { n: 1 },
      };
    case UPDATE_COURSE_STATUS: {
      let users = [...state.coursesData];
      let ind = state.coursesData?.courses.findIndex(
        (obj) => obj.id === action?.payload?.id
      );
      console.log(ind, )
      users[ind].status = action?.payload?.status;
      return {
        ...state,
        coursesData: [...users],
      };
    }
    case UPDATE_SEO_SUCCESS: {
      let seoData = [...state?.coursesData.courses];
      let ind = state.coursesData?.courses.findIndex(
        (obj) => obj.id === action?.payload?.data?.id
      );
      seoData[ind] = action?.payload?.data
      state.coursesData.courses = [...seoData]
      return {
        ...state,
        coursesData: {...state.coursesData},
      };
    }

    case UPDATE_EPISODE_STATUS: {
      let episodes = [...state?.courseDetails?.episodes];
      let indEpi = episodes?.findIndex((obj) => obj.id === action?.payload?.id);
      episodes[indEpi].status = action?.payload?.status;
      return {
        ...state,
        courseDetails: { ...state.courseDetails, episodes: [...episodes] },
      };
    }
    default: {
      return state;
    }
  }
};

export default CourseReducer;
