import { DELETE_SUGGESTION, GET_ALL_SUGGESTIONS,SUGGESTION_lOADER } from "../actions/types";

const initialState = {
  suggestionList: [],
  suggestionLoader:false
};

const CategoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUGGESTIONS: {
      return {
        ...state,
        suggestionList: action.payload,
      };
    }
    case SUGGESTION_lOADER: {
      return {
        ...state,
        suggestionLoader: action.payload,
      };
    }
    case DELETE_SUGGESTION: {
      let category = [...state?.suggestionList];
      let ind = state?.suggestionList?.findIndex(
        (currentValue) => currentValue?.id === action?.payload
      );
      if (ind >= 0) {
        category.splice(ind, 1);
        return {
          ...state,
          suggestionList: [...category],
        };
      }
      break;
    }
    default: {
      return state;
    }
  }
};

export default CategoryReducer;
