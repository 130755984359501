import React from "react";


const retreatRoutes = [
  {
    path: "/teacher-training/details/:id",
    exact: true,
    component: React.lazy(() => import("./Details")),
  },
  {
    path: "/teacher-trainings",
    exact: true,
    component: React.lazy(() => import("./index")),
  },
];

export default retreatRoutes;
