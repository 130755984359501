import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  IconButton,
  CircularProgress,
  Card,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../../assets/logo.png";
// import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > *": {
      width: "100%",
      margin: "20px 0px",
    },
  },

  signInBtn: {
    background: "linear-gradient(186.43deg, #15AEBF 13.46%, #14D9D9 76.64%)",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    borderRadius: "47px",
    width: "355px",
    height: "69px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none",
  },
  signInBtnIcon: {
    background: "linear-gradient(210.1deg, #15AEBF 13.46%, #14D9D9 76.64%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    width: "initial",
    height: "initial",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    transform: "rotate(75deg)",
    padding: "1rem",
    color: "#FFFFFF",
    textTransform: "none",
  },
  forwardIcon: {
    transform: "rotate(-75deg)",
  },
}));

const Login = () => {
  // let history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = (data) => {
    dispatch(loginWithEmailAndPassword({ ...data, url: "login" }));
  };

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div className="signup flex justify-center w-full h-full-screen">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-8 flex justify-center items-center h-full">
                <img src={Logo} alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-9 h-full bg-light-gray position-relative">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={classes.root}>
                    <TextField
                      className={classes.inputField}
                      inputRef={register({
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid Email",
                        },
                      })}
                      label="Email"
                      name="email"
                      helperText={errors.email && errors.email.message}
                      error={!!errors.email}
                      type="text"
                    />

                    <TextField
                      className={classes.inputField}
                      type={values.showPassword ? "text" : "password"}
                      label="Password"
                      inputRef={register({
                        required: "Email Required",
                        minLength: {
                          value: 5,
                          message: "Email Error",
                        },
                      })}
                      name="password"
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loginState.loading}
                          type="submit"
                        >
                          {!loginState.loading ? (
                            "Sign in"
                          ) : (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </div>
                      {/* <span className="mr-2 ml-5">or</span>
                      <Button
                        className="capitalize"
                        onClick={() => history.push("/signup")}
                      >
                        Sign up
                      </Button> */}
                    </div>
                    {/* <Button
                      style={{ margin: "0px" }}
                      className="text-primary"
                      onClick={() => history.push("/forgot-password")}
                    >
                      Forgot password?
                    </Button> */}
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default Login;
