import React from "react";

const QuotesRoutes = [
  {
    path: "/admins",
    exact: true,
    component: React.lazy(() => import("./index")),
  },
  {
    path: "/admins/add",
    component: React.lazy(() => import("./AddAdmin")),
  },
  {
    path: "/admins/edit/:id",
    component: React.lazy(() => import("./AddAdmin")),
  },
  {
    path: "/profile",
    exact: true,
    component: React.lazy(() => import("./Profile")),
  },
  {
    path: "/change-password",
    component: React.lazy(() => import("./ChangePassword")),
  },
  {
    path: "/profile/edit",
    component: React.lazy(() => import("./EditProfile")),
  },
];

export default QuotesRoutes;
