import { LOADER } from "../actions/types";

const initialState = {
    loader:false,
};

const Loader = function(state = initialState, action) {
  switch (action.type) {
    case LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default Loader;