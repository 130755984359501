// import React, { Component } from "react";
// import { Button } from "@material-ui/core";
// import { withStyles } from "@material-ui/styles";

// const styles = theme => ({
//   flexCenter: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center"
//   },
//   wrapper: {
//     width: "100%",
//     height: "100vh"
//   },
//   inner: {
//     flexDirection: "column",
//     maxWidth: "320px"
//   }
// });

// class NotFound extends Component {
//   state = {};

//   render() {
//     const { classes } = this.props;
//     return (
//       <div className={`${classes.flexCenter} ${classes.wrapper}`}>
//         <div className={`${classes.flexCenter} ${classes.inner}`}>
//           <img
//             className="mb-8"
//             src="/assets/images/illustrations/404.svg"
//             alt=""
//           />
//           <Button
//             className="capitalize"
//             variant="contained"
//             color="primary"
//             onClick={() => this.props.history.push("/")}
//           >
//             Back to Dashboard
//           </Button>
//         </div>
//       </div>
//     );
//   }
// }

// export default withStyles(styles, { withTheme: true })(NotFound);




import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import {  useHistory } from "react-router-dom";
import RelateIcon from "../../../assets/RelateIcon.png";


function NotFound() {
    const history = useHistory();
    const classes = useStyles();

    return (
        <Grid container
        // style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
        >
            <Grid item xs={2} md={3}>
                <div
                    className={classes.bottomLeft}
                    style={{ backgroundColor: "#a6eae9" }}
                />
            </Grid>
            <Grid item container xs={8} md={6} md style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Grid item xs={12} style={{ textAlign: "center", zIndex: 1 }}>
                    <a href={process.env.REACT_APP_LANDING_URL}>
                        <img src={RelateIcon} alt="logo" style={{ marginTop: "30px", }} />
                    </a>
                </Grid>

                <Grid item xs={12} container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>


                    <Grid item xs={12}>
                        <Typography className={classes.messageHeading}>
                            404 Not Found
                        </Typography>

                        <Typography className={classes.messageSubHeading}>
                            The resource requested could not found on the server!
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.center}>
                        <Typography onClick={() => history.push('/')} className={classes.link}>Back To Dashboard</Typography>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item container xs={2} md={3} justify='flex-end'>
                <Grid item xs={12} style={{ flexBasis: 0 }}>
                    <div className={classes.topRightCircle}></div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default NotFound;


const useStyles = makeStyles(theme => ({
    messageHeading: {
        fontFamily:'Reef',
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "30px",
        textAlign: 'center',
        // lineHeight: "49px",
        color: "#000",
        paddingBottom: '15px'
    },
    messageSubHeading: {
        fontFamily:'Reef',
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "16px",
        textAlign: 'center',
        // lineHeight: "49px",
        color: "#a9a9a9",
        paddingBottom: '15px'
    },
    iconStyle: {
        color: 'red',
        height: '100px',
        width: '100px',
        textAlign: 'center'
    },
    center: {
        textAlign: 'center'
    },
    link: {
        fontFamily:'Reef',
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "18px",
        textAlign: 'center',
        cursor:'pointer',
        color:'#09A9BB',
        textDecoration:'underline'
    },
    bottomLeft: {
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        position: 'fixed',
        bottom: 30,
        marginLeft: "50px",
        // marginBottom: "50px"
    },

    topRightCircle: {
        width: "150px",
        height: "150px",
        borderRadius: "2% 0px 2% 100%",
        float: "right",
        backgroundColor: "#09A9BB",
        opacity: "0.5"
    }
}))