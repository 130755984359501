import { GET_ALL_SUBCATEGORIES, DELETE_SUBCATEGORY } from "../actions/types";

const initialState = {
  subcategoriesList: [],
};

const CategoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBCATEGORIES: {
      return {
        ...state,
        subcategoriesList: action.payload,
      };
    }
    case DELETE_SUBCATEGORY: {
      let category = [...state.subcategoriesList];
      category.splice(action.payload, 1);
      return {
        ...state,
        subcategoriesList: [...category],
      };
    }
    default: {
      return state;
    }
  }
};

export default CategoryReducer;
