import React from "react";

const contactsRoutes = [
  {
    path: "/contacts",
    component: React.lazy(() => import("./index")),
  },
];

export default contactsRoutes;
