import {
  // GET_ALL_COURSES,
  // GET_COURSE_BY_ID,
  // REMOVE_COURSE_DETAILS_LOCAL,
  // UPDATE_COURSE_STATUS,
  // UPDATE_EPISODE_STATUS,
  // COURSE_LOADER,
  EMAIL_LOGS,
} from "../actions/types";

const initialState = {
  emailLogs: {},

  loader: { show: false, error: false },
};

const EmailLogsReducer = function (state = initialState, action) {
  switch (action.type) {
    case EMAIL_LOGS: {
      return {
        ...state,
        emailLogs: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default EmailLogsReducer;
