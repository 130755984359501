import {
  GET_ALL_SESSIONS,
  GET_SESSION_BY_ID,
  SESSION_LOADER,
  REMOVE_SESSION_DETAILS,
  GET_SESSION_PARTICIPANTS
} from "../actions/types";

const initialState = {
  sessionsData: {},
  sessionDetails: {},
  loader: { show: false, error: false },
};

const SessionsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SESSION_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case GET_SESSION_BY_ID:
      return {
        ...state,
        sessionDetails: action.payload,
      };
    case GET_SESSION_PARTICIPANTS:
      return {
        ...state,
        sessionDetails: {...state.sessionDetails, sessionParticipants:action.payload},
      };
    case REMOVE_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: {},
      };
    case GET_ALL_SESSIONS: {
      return {
        ...state,
        sessionsData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default SessionsReducer;
