import { GET_ALL_CONFIGS,CONFIG_LOADER } from "../actions/types";

const initialState = {
  allConfigs: [],
  loader:false
};

const ConfigsReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONFIGS: {
      return {
        ...state,
        allConfigs: action.payload,
      };
    }
    case CONFIG_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
   
    default: {
      return state;
    }
  }
};

export default ConfigsReducer;
