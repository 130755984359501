import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
} from "../actions/UserActions";
import {
  UPDATE_ADMIN_PROFILE,
  UPDATE_ADMIN_PROFILE_PIC,
} from "../actions/types";
const initialState = {};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case UPDATE_ADMIN_PROFILE: {
      return {
        ...state,
        admin: {
          ...state?.admin,
          name: action?.payload?.name,
          userName: action?.payload?.userName,
        },
      };
    }
    case UPDATE_ADMIN_PROFILE_PIC: {
      return {
        ...state,
        admin: {
          ...state?.admin,
          pictureUrl: action?.payload?.pictureUrl,
        },
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state,
      };
    }
    case USER_LOGGED_OUT: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
