import React from "react";

const emailRoutes = [
  {
    path: "/email-log",
    exact: true,
    component: React.lazy(() => import("./index")),
  },
];

export default emailRoutes;
