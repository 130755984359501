import React from "react";

const userRoutes = [
  {
    path: "/users/details/:id",
    component: React.lazy(() => import("./Details")),
  },
  {
    path: "/users/:role",
    component: React.lazy(() => import("./index")),
  },
];

export default userRoutes;
