import { GET_ALL_CATEGORIES, DELETE_CATEGORY } from "../actions/types";

const initialState = {
  categoriesList: [],
};

const CategoryReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CATEGORIES: {
      return {
        ...state,
        categoriesList: action.payload,
      };
    }
    case DELETE_CATEGORY: {
      let category = [...state.categoriesList];
      category.splice(action.payload, 1);
      return {
        ...state,
        categoriesList: [...category],
      };
    }
    default: {
      return state;
    }
  }
};

export default CategoryReducer;
