import API from "../../utils/API";
import {
  GET_ALL_CONFIGS,
  CONFIG_LOADER,
  SHOW_MESSAGE,

} from "./types";


//Get all configs

export const getAllConfigs = () => async (dispatch) => {
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };
    // set body
    try {
      // get all users
      const res = await API.get("/api/v1/config/", config);
      dispatch({
        type: GET_ALL_CONFIGS,
        payload: res?.data?.data,
      });
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error.response.data.message
              : ` failed`,
          severity: "error",
        },
      });
    }
  };

//Get all configs

export const uploadLandingBackgroundImage = ({imageFile,token,id}) => async (dispatch) => {
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // set body
    let formData = new FormData();
      formData.append("image", imageFile);
      const body = formData;
    try {
      // get all users
      const res = await API.patch(`/api/v1/config/upload-config-image?configId=${id}`,body, config);
      
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Success`,
          message: res.data.message,
          severity: "success",
        },
      });

    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error.response.data.message
              : ` failed`,
          severity: "error",
        },
      });
    }
  };

//  update config
export const updateConfig = ({ token, value, configId }) => async (dispatch) => {
  // set header
  dispatch({
    type: CONFIG_LOADER,
    payload:true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  // set body

  const body = JSON.stringify({value, configId});

  try {
    // update category
    const res = await API.patch(
      `/api/v1/config`,
      body,
      config
    );
    dispatch({
      type: CONFIG_LOADER,
      payload:false,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Success`,
        message: res.data.message,
        severity: "success",
      },
    });
  
  } catch (error) {
    dispatch({
        type: CONFIG_LOADER,
        payload:false,
      });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
  }
};
