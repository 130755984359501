import jwtAuthService from "../../services/jwtAuthService";
import { setUserData } from "./UserActions";
import history from "history.js";
import API from "../../utils/API";
import { SHOW_MESSAGE } from "./types";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

// login user
export const loginWithEmailAndPassword = ({ email, password }) => async (
  dispatch
) => {
  dispatch({
    type: LOGIN_LOADING,
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  const body = JSON.stringify({ email, password });
  try {
    // login user
    const res = await API.post("/api/v1/admin/admin-login", body, config);
    // save data to localstorage
    jwtAuthService.loginWithEmailAndPassword(res.data.data);
    dispatch(setUserData(res.data.data));

    history.push({
      pathname: "/",
    });
    return dispatch({
      type: LOGIN_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: error?.response?.data?.message,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data?.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
    
  }
};
//signup user
export const signupUser = ({ email, password, Username, name }) => async (
  dispatch
) => {
  dispatch({
    type: LOGIN_LOADING,
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  const body = JSON.stringify({
    email,
    password,
    name,
    userName: Username,
  });
  try {
    // signup user
    const res = await API.post("/api/v1/admin/", body, config);
    dispatch(setUserData(res.data.data));

    history.push({
      pathname: "/",
    });
    return dispatch({
      type: LOGIN_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
    dispatch({
      type: LOGIN_ERROR,
      payload: error.response.data.message,
    });
  }
};
// export function loginWithEmailAndPassword({ email, password }) {
//   return (dispatch) => {
//     dispatch({
//       type: LOGIN_LOADING,
//     });

//     jwtAuthService
//       .loginWithEmailAndPassword(email, password)
//       .then((user) => {
//         dispatch(setUserData(user));

//         history.push({
//           pathname: "/",
//         });

//         return dispatch({
//           type: LOGIN_SUCCESS,
//         });
//       })
//       .catch((error) => {
//         return dispatch({
//           type: LOGIN_ERROR,
//           payload: error,
//         });
//       });
//   };
// }

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}
