import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  IconButton,
  Card,
  Grid,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { signupUser } from "../../redux/actions/LoginActions";
import Logo from "../../../assets/logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      margin: "20px 0px",
    },
  },
  signUpBtn: {
    background: "linear-gradient(186.43deg, #F15151 13.46%, #F11010 76.64%)",
    boxShadow: " 0px 3px 6px rgba(255, 0, 0, 0.161)",
    borderRadius: " 104px",
    width: "355px",
    height: "69px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none",
  },
  signInBtnIcon: {
    background: "linear-gradient(210.1deg, #F11010 13.46%, #F15151 76.64%);",
    boxShadow: "0px 3px 6px rgba(255, 0, 0, 0.161)",
    width: "initial",
    height: "initial",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    transform: "rotate(75deg)",
    padding: "1rem",
    color: "#FFFFFF",
    textTransform: "none",
  },
  forwardIcon: {
    transform: "rotate(-75deg)",
  },
}));
const Signup = () => {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);

  const { register, errors, handleSubmit } = useForm();
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    password: "",
    Username: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const onSubmit = (data) => {
    dispatch(signupUser({ ...data }));
  };

  return (
    <div className="signup flex justify-center w-full h-full-screen">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-8 flex justify-center bg-light-gray items-center h-full">
                <img src={Logo} alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-9 h-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={classes.root}>
                    <TextField
                      inputRef={register({
                        required: "name is required!",
                      })}
                      label="Name"
                      name="name"
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                      type="text"
                    />
                    <TextField
                      inputRef={register({
                        required: "username is required!",
                      })}
                      label="Username"
                      name="Username"
                      error={!!errors.Username}
                      helperText={errors.Username && errors.Username.message}
                      type="text"
                    />
                    <TextField
                      className={classes.inputField}
                      inputRef={register({
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid Email",
                        },
                      })}
                      label="Email"
                      name="email"
                      helperText={errors.email && errors.email.message}
                      error={!!errors.email}
                      type="text"
                    />

                    <TextField
                      className={classes.inputField}
                      type={values.showPassword ? "text" : "password"}
                      label="Password"
                      inputRef={register({
                        required: "Email Required",
                        minLength: {
                          value: 5,
                          message: "Email Error",
                        },
                      })}
                      name="password"
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="flex items-center">
                      <Button
                        className="capitalize"
                        variant="contained"
                        color="primary"
                        disabled={loginState.loading}
                        type="submit"
                      >
                        {!loginState.loading ? (
                          "Sign up"
                        ) : (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                      <span className="mx-2 ml-5">or</span>
                      <Button
                        className="capitalize"
                        onClick={() => history.push("/signin")}
                      >
                        Sign in
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default Signup;
