import {
  GET_ALL_USERS,
  UPDATE_USER,
  GET_USER_BY_ID,
  REMOVE_USER_DETAILS_LOCAL,
  USERS_LOADER,GET_USER_OTP,
} from "../actions/types";

const initialState = {
  usersData: {},
  userDetails: {},
  otp: null,
  loader: { show: false, error: false },
};

const UsersReducer = function (state = initialState, action) {
  switch (action.type) {
    case USERS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        userDetails: action.payload,
      };
    case REMOVE_USER_DETAILS_LOCAL:
      return {
        ...state,
        userDetails: {},
      };
    case GET_ALL_USERS: {
      return {
        ...state,
        usersData: action.payload,
      };
    }
    case GET_USER_OTP: {
      return {
        ...state,
        otp: action.payload,
      };
    }
    case UPDATE_USER: {
      let users = [...state.usersData];
      let ind = state.usersData?.users?.findIndex(
        (obj) => obj.id === action?.payload?.id
      );
      users[ind].status = action?.payload?.status;
      return {
        ...state,
        usersData: [...users],
      };
    }
    default: {
      return state;
    }
  }
};

export default UsersReducer;
