import { GET_ALL_SUBSCRIPTIONS, SUBSCRIPTIONS_COUNT } from "../actions/types";

const initialState = {
  subscriptionsList: [],
  count: 37,
};

const SubscriptionsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptionsList: action.payload,
      };
    }
    case SUBSCRIPTIONS_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default SubscriptionsReducer;
