import React from "react";

const retreatRoutes = [
  {
    path: "/retreat/details/:id",
    exact: true,
    component: React.lazy(() => import("./Details")),
  },
  {
    path: "/retreats",
    exact: true,
    component: React.lazy(() => import("./index")),
  },
];

export default retreatRoutes;
