//message
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const GO_BACK = "GO_BACK";

//users
export const GET_ALL_USERS = "GET_ALL_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const USER_COUNT = "USER_COUNT";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const REMOVE_USER_DETAILS_LOCAL = "REMOVE_USER_DETAILS_LOCAL";
export const USERS_LOADER = "USERS_LOADER";
export const GET_USER_OTP = "GET_USER_OTP";

//categories
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const CATEGORY_COUNT = "CATEGORY_COUNT";

//subcategories
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const GET_ALL_SUBCATEGORIES = "GET_ALL_SUBCATEGORIES";
export const SUBCATEGORY_COUNT = "SUBCATEGORY_COUNT";

//courses
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const GET_COURSE_BY_ID = "GET_COURSE_BY_ID";
export const COURSE_LOADER = "COURSE_LOADER";
export const REMOVE_COURSE_DETAILS_LOCAL = "REMOVE_COURSE_DETAILS_LOCAL";
export const UPDATE_EPISODE_STATUS = "UPDATE_EPISODE_STATUS";
export const UPDATE_COURSE_STATUS = "UPDATE_COURSE_STATUS";
export const COURSES_COUNT = "COURSES_COUNT";
export const UPDATE_SEO_SUCCESS = "UPDATE_SEO_SUCCESS";
export const UPDATE_SEO_FAILURE = "UPDATE_SEO_FAILURE";
export const UPDATE_COMMISSION_SUCCESS = "UPDATE_COMMISSION_SUCCESS";
export const UPDATE_COMMISSION_FAILURE = "UPDATE_COMMISSION_SUCCESS";


//sessions
export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const SESSIONS_COUNT = "SESSIONS_COUNT";
export const GET_SESSION_BY_ID = "GET_SESSION_BY_ID";
export const REMOVE_SESSION_DETAILS = "REMOVE_SESSION_DETAILS";
export const SESSION_LOADER = "SESSION_LOADER";
export const GET_SESSION_PARTICIPANTS = "GET_SESSION_PARTICIPANTS";

//CHALLENGES
export const GET_ALL_CHALLENGES = "GET_ALL_CHALLENGES";
export const CHALLENGES_COUNT = "CHALLENGES_COUNT";
export const GET_CHALLENGE_BY_ID = "GET_CHALLENGE_BY_ID";
export const REMOVE_CHALLENGE_DETAILS = "REMOVE_CHALLENGE_DETAILS";
export const CHALLENGE_LOADER = "CHALLENGE_LOADER";

//Plan
export const ADD_PLAN = "ADD_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const GET_ALL_PLANS = "GET_ALL_PLAN";
export const PLAN_COUNT = "PLAN_COUNT";

//Dashboard

export const WEEKLY_JOIN_COURSES = "WEEKLY_JOIN_COURSES";
export const LAST_MONTH_NEW_USERS = "LAST_MONTH_NEW_USERS";
export const LAST_MONTH_JOIN_COURSES = "LAST_MONTH_JOIN_COURSES";
export const LAST_MONTH_NEW_COURSES = "LAST_MONTH_NEW_COURSES";
export const COUNT_ALL_COURSES = "COUNT_ALL_COURSES";
export const TOP_COURSES = "TOP_COURSES";
export const LAST_WEEK_NEW_USERS_DETAILS = "LAST_WEEK_NEW_USERS_DETAILS";
export const LAST_MONTH_NEW_USERS_DETAILS = "LAST_MONTH_NEW_USERS_DETAILS";
export const LAST_YEAR_NEW_USERS_DETAILS = "LAST_YEAR_NEW_USERS_DETAILS";
export const LAST_MONTH_NEW_COURSES_DETAILS = "LAST_MONTH_NEW_COURSES_DETAILS";
export const LAST_WEEK_CREATED_SESSION = "LAST_WEEK_CREATED_SESSION";
export const LAST_WEEK_CREATED_COURSES = "LAST_WEEK_CREATED_COURSES";
export const LAST_MONTH_SUBSCRIPTIONS = "LAST_MONTH_SUBSCRIPTIONS";
export const GET_DASHBOARD_GRAPH_INFO = "GET_DASHBOARD_GRAPH_INFO";

//SUBSCRIPTIONS

export const GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS";
export const SUBSCRIPTIONS_COUNT = "SUBSCRIPTIONS_COUNT";

//Suggestions
export const GET_ALL_SUGGESTIONS = "GET_ALL_SUGGESTIONS";
export const DELETE_SUGGESTION = "DELETE_SUGGESTION";
export const SUGGESTION_lOADER = "SUGGESTION_lOADER";

// quotes
export const ADD_QUOTE = "ADD_QUOTE";
export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const DELETE_QUOTE = "DELETE_QUOTE";
export const GET_ALL_QUOTES = "GET_ALL_QUOTES";

//admin
export const ADD_ADMIN = "ADD_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_PROFILE = "UPDATE_ADMIN_PROFILE";
export const UPDATE_ADMIN_PROFILE_PIC = "UPDATE_ADMIN_PROFILE_PIC";

//configs

export const GET_ALL_CONFIGS = "GET_ALL_CONFIGS";
export const CONFIG_LOADER = "CONFIG_LOADER";

//loader
export const LOADER = "LOADER";

//retreats
export const GET_ALL_RETREATS = "GET_ALL_RETREATS";
export const GET_RETREAT_BY_ID = "GET_RETREAT_BY_ID";
export const RETREAT_LOADER = "RETREAT_LOADER";
export const REMOVE_RETREAT_DETAILS_LOCAL = "REMOVE_RETREAT_DETAILS_LOCAL";
export const UPDATE_LESSON_STATUS = "UPDATE_LESSON_STATUS";
export const UPDATE_RETREAT_STATUS = "UPDATE_RETREAT_STATUS";
export const RETREAT_COUNT = "RETREAT_COUNT";
export const REMOVE_LESSON_DETAILS_LOCAL = "REMOVE_LESSON_DETAILS_LOCAL";
export const CHANGE_RETREAT_SECTION_STATUS = "CHANGE_RETREAT_SECTION_STATUS";
export const DELETE_RETREAT_SECTION_STATUS = "DELETE_RETREAT_SECTION_STATUS";

//teacher training
export const GET_ALL_TEACHER_TRAININGS = "GET_ALL_TEACHER_TRAININGS";

//email  logs
export const EMAIL_LOGS = "EMAIL_LOGS";
