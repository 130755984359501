import React,{useEffect} from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setLayoutSettings } from "app/redux/actions/LayoutActions";
import CssBaseline from "@material-ui/core/CssBaseline";
import MatxCssVars from "./MatxCssVars";
import { getAllConfigs } from '../../redux/actions/Configuration'
import {useDispatch} from 'react-redux'

const MatxTheme = ({ children, settings }) => {
  const dispatch = useDispatch()
  let activeTheme = { ...settings.themes[settings.activeTheme] };

  useEffect(()=>{
    dispatch(getAllConfigs())
  },[]);

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <MatxCssVars> {children} </MatxCssVars>
    </ThemeProvider>
  );
};

MatxTheme.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.layout.settings,
  setLayoutSettings: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { setLayoutSettings })(MatxTheme);
