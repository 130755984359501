import React from "react";

const SessionRoutes = [
  {
    path: "/sessions/details/:id",
    component: React.lazy(() => import("./Details")),
  },
  {
    path: "/sessions",
    component: React.lazy(() => import("./index")),
  },
];

export default SessionRoutes;
