import {
  GET_ALL_RETREATS,
  GET_RETREAT_BY_ID,
  REMOVE_LESSON_DETAILS_LOCAL,
  UPDATE_RETREAT_STATUS,
  UPDATE_LESSON_STATUS,
  CHANGE_RETREAT_SECTION_STATUS,
  DELETE_RETREAT_SECTION_STATUS,
  RETREAT_LOADER,
} from "../actions/types";

const initialState = {
  retreatsData: {},
  retreatDetails: {},
  loader: { show: false, error: false },
};

const RetreatReducer = function (state = initialState, action) {
  switch (action.type) {
    case RETREAT_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case GET_ALL_RETREATS: {
      return {
        ...state,
        retreatsData: action.payload,
      };
    }
    case CHANGE_RETREAT_SECTION_STATUS: {
      const sectionsLocal = state?.retreatDetails?.sections?.map(item=> item?.id === action?.payload?.sectionId ? {...item, status: action?.payload?.status} : item);
      
      return {
        ...state,
        retreatDetails:{...state.retreatDetails, sections: sectionsLocal},
      };
    }
    case DELETE_RETREAT_SECTION_STATUS: {
      const sectionsLocal = state?.retreatDetails?.sections?.filter(item=> item?.id !== action?.payload?.sectionId );
      
      return {
        ...state,
        retreatDetails:{...state.retreatDetails, sections: sectionsLocal},
      };
    }
    case GET_RETREAT_BY_ID:
      return {
        ...state,
        retreatDetails: action.payload,
      };
    case REMOVE_LESSON_DETAILS_LOCAL:
      return {
        ...state,
        retreatDetails: {  },
      };
    case UPDATE_RETREAT_STATUS: {
      let users = [...state.retreatsData];
      let ind = state.retreatsData?.courses.findIndex(
        (obj) => obj.id === action?.payload?.id
      );
      users[ind].status = action?.payload?.status;
      return {
        ...state,
        retreatsData: [...users],
      };
    }
    case UPDATE_LESSON_STATUS: {
      let episodes = [...state?.retreatDetails?.episodes];
      let indEpi = episodes?.findIndex((obj) => obj.id === action?.payload?.id);
      episodes[indEpi].status = action?.payload?.status;

      return {
        ...state,
        retreatDetails: { ...state.retreatDetails, episodes: [...episodes] },
      };
    }
    default: {
      return state;
    }
  }
};

export default RetreatReducer;
