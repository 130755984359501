import {
  ADD_ADMIN,
  DELETE_ADMIN,
  GET_ALL_ADMINS,
  UPDATE_ADMIN,
} from "../actions/types";

const initialState = {
  adminsData: {},
};

const adminReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ADMINS: {
      return {
        ...state,
        adminsData: action.payload,
      };
    }
    case DELETE_ADMIN: {
      let admin = [...state.adminsData?.admins];
      let ind = admin.findIndex((q) => q.id === action.payload);
      if (ind >= 0) {
        admin.splice(ind, 1);
      }
      return {
        ...state,
        adminsData: [...admin],
      };
    }
    case ADD_ADMIN: {
      let admin = [...state.adminsData?.admins];
      return {
        ...state,
        adminsData: [...admin, action?.payload],
      };
    }
    case UPDATE_ADMIN: {
      let admin = [...state.adminsData?.admins];
      let ind = state?.adminsData?.findIndex(
        (obj) => obj?.id === action?.payload?.id
      );
      console.log("error", ind);
      console.log("admin", admin);
      admin[ind].status = action?.payload?.status;
      console.log("ind", ind);

      return {
        ...state,
        adminsData: [...admin],
      };
    }
    default: {
      return state;
    }
  }
};

export default adminReducer;
