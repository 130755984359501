import {
  GET_ALL_CHALLENGES,
  CHALLENGE_LOADER,
  GET_CHALLENGE_BY_ID,
  REMOVE_CHALLENGE_DETAILS,
} from "../actions/types";

const initialState = {
  challengesData: {},
  challengeDetails: {},
  loader: { show: false, error: false },
};

const ChallengesReducer = function (state = initialState, action) {
  switch (action.type) {
    case CHALLENGE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case GET_CHALLENGE_BY_ID:
      return {
        ...state,
        challengeDetails: action.payload,
      };
    case REMOVE_CHALLENGE_DETAILS:
      return {
        ...state,
        challengeDetails: {},
      };
    case GET_ALL_CHALLENGES: {
      return {
        ...state,
        challengesData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default ChallengesReducer;
