import { GET_ALL_PLANS, DELETE_PLAN } from "../actions/types";

const initialState = {
  plansList: [],
};

const PlansReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PLANS: {
      return {
        ...state,
        plansList: action.payload,
      };
    }
    case DELETE_PLAN: {
      let category = [...state.plansList];
      category.splice(action.payload, 1);
      return {
        ...state,
        plansList: [...category],
      };
    }
    default: {
      return state;
    }
  }
};

export default PlansReducer;
