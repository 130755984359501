import {
  WEEKLY_JOIN_COURSES,
  LAST_MONTH_NEW_USERS,
  LAST_MONTH_JOIN_COURSES,
  LAST_MONTH_NEW_COURSES,
  LAST_MONTH_SUBSCRIPTIONS,
  COUNT_ALL_COURSES,
  TOP_COURSES,
  LAST_WEEK_NEW_USERS_DETAILS,
  LAST_MONTH_NEW_USERS_DETAILS,
  LAST_YEAR_NEW_USERS_DETAILS,
  GET_DASHBOARD_GRAPH_INFO
} from "../actions/types";

const initialState = {
  weeklyJoinCourses: {},
  lastMonthNewUsers: {},
  lastMonthJoinCourses: {},
  lastMonthNewCourses: {},
  lastMonthSubscriptios: {},
  counts: {},
  topCourses: [],
  lastWeekNewUsers: {},
  lastYearNewUsers: {},
  dashboardGraphInfo:{}
};

const DashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case WEEKLY_JOIN_COURSES: {
      return {
        ...state,
        weeklyJoinCourses: action.payload,
      };
    }
    case GET_DASHBOARD_GRAPH_INFO: {
      return {
        ...state,
        dashboardGraphInfo: action.payload,
      };
    }
    case LAST_MONTH_NEW_USERS: {
      return {
        ...state,
        lastMonthNewUsers: action.payload,
      };
    }
    case LAST_MONTH_JOIN_COURSES: {
      return {
        ...state,
        lastMonthJoinCourses: action.payload,
      };
    }
    case LAST_MONTH_NEW_COURSES: {
      return {
        ...state,
        lastMonthNewCourses: action.payload,
      };
    }
    case LAST_MONTH_SUBSCRIPTIONS: {
      return {
        ...state,
        lastMonthSubscriptios: action.payload,
      };
    }
    case COUNT_ALL_COURSES: {
      return {
        ...state,
        counts: action.payload,
      };
    }
    case TOP_COURSES: {
      return {
        ...state,
        topCourses: action.payload,
      };
    }
    case LAST_WEEK_NEW_USERS_DETAILS: {
      return {
        ...state,
        lastWeekNewUsers: action.payload,
      };
    }
    case LAST_MONTH_NEW_USERS_DETAILS: {
      return {
        ...state,
        lastMonthNewUsers: action.payload,
      };
    }
    case LAST_YEAR_NEW_USERS_DETAILS: {
      return {
        ...state,
        lastYearNewUsers: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default DashboardReducer;
