import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import NotificationReducer from "./NotificationReducer";
import NavigationReducer from "./NavigationReducer";
import MessageReducer from "./MessageReducer";
import CategoryReducer from "./CategoryReducer";
import UsersReducer from "./UsersReducer";
import CourseReducer from "./CoursesReducer";
import RetreatsReducer from "./RetreatsReducer";
import SessionReducer from "./SessionReducer";
import ChallengeReducer from "./ChallengeReducer";
import SubCategoryReducer from "./SubCategoryReducer";
import PlanReducer from "./PlanReducer";
import DashboardReducer from "./DashboardReducer";
import SubscriptionsReducer from "./SubscriptionsReducer";
import SuggestionReducer from "./SuggestionReducer";
import QuoteReducer from "./QuoteReducer";
import AdminReducer from "./AdminReducer";
import ConfigsReducer from "./Configuration";
import Loader from "./Loader";
import EmailLogsReducer from "./EmailLogsReducer";

import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
export default persistReducer(
  {
    key: "rrsb", // key is required
    storage, // storage is now required
    whitelist: ["user"],
  },
  combineReducers({
    login: LoginReducer,
    user: UserReducer,
    layout: LayoutReducer,
    notification: NotificationReducer,
    navigations: NavigationReducer,
    message: MessageReducer,
    categories: CategoryReducer,
    subcategories: SubCategoryReducer,
    users: UsersReducer,
    courses: CourseReducer,
    retreats: RetreatsReducer,
    session: SessionReducer,
    challenges: ChallengeReducer,
    plan: PlanReducer,
    dashboard: DashboardReducer,
    subscription: SubscriptionsReducer,
    suggestion: SuggestionReducer,
    quote: QuoteReducer,
    admins: AdminReducer,
    configs: ConfigsReducer,
    loader: Loader,
    emailLogs: EmailLogsReducer,
  })
);
