import React from "react";

const categoryRoutes = [
  {
    path: "/subcategories",
    component: React.lazy(() => import("./index")),
  },
  {
    path: "/add-subcategory",
    component: React.lazy(() => import("./AddSubCategory")),
  },
  {
    path: "/edit-subcategory/:id",
    component: React.lazy(() => import("./AddSubCategory")),
  },
];

export default categoryRoutes;
