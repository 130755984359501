import { ADD_QUOTE, GET_ALL_QUOTES, DELETE_QUOTE } from "../actions/types";

const initialState = {
  quotesList: [],
};

const quoteReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_QUOTES: {
      return {
        ...state,
        quotesList: action.payload,
      };
    }
    case DELETE_QUOTE: {
      let quote = [...state.quotesList];
      let ind = quote.findIndex((q) => q.id === action.payload);
      if (ind >= 0) {
        quote.splice(ind, 1);
      }
      return {
        ...state,
        quotesList: [...quote],
      };
    }
    case ADD_QUOTE: {
      let quote = [...state.quotesList];
      return {
        ...state,
        quotesList: [...quote, action?.payload],
      };
    }
    default: {
      return state;
    }
  }
};

export default quoteReducer;
