export const authRoles = {
  //sa: ['Admin'], // Only Super Admin has access
  admin: ["Admin"], // Only SA & Admin has access
  editor: ['Sa', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
  viewer: ['Sa', 'Admin', 'Editor', 'Viewer'],
  seo: ['Sa', 'Admin', 'Editor', 'Guest','Seoengineer']// Everyone has access
}



// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];
