import React from "react";

const ChallengesRoutes = [
  {
    path: "/challenges/details/:id",
    component: React.lazy(() => import("./Details")),
  },
  {
    path: "/challenges",
    component: React.lazy(() => import("./index")),
  },
];

export default ChallengesRoutes;
