import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ClassIcon from "@material-ui/icons/Class";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import BurstModeIcon from "@material-ui/icons/BurstMode";
// import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import SettingsIcon from "@material-ui/icons/Settings";
import CardMembership from "@material-ui/icons/CardMembership";
import AccountBalance from "@material-ui/icons/AccountBalance";
import FormatQuoteIcon from "@material-ui/icons/FormatQuoteRounded";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Person from "@material-ui/icons/Person";
import Assessment from "@material-ui/icons/Assessment";
import Contacts from "@material-ui/icons/Contacts";
import EmailIcon from "@material-ui/icons/Email";
import { authRoles } from "../../src/app/auth/authRoles";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard/analytics",
    icon: "dashboard",
    role: authRoles.viewer,
  },
  {
    icon: <PeopleIcon style={{ fontSize: "19px" }} />,
    name: "Users",
    role: authRoles.seo,
    children: [
      {
        name: "All Users",
        path: "/users/all",
        role: authRoles.seo,
      },
      {
        name: "Instructor",
        path: "/users/instructor",
        role: authRoles.seo,
      },
      {
        name: "Regular Users",
        path: "/users/regular",
        role: authRoles.seo,
      },

      {
        name: "Studio",
        path: "/users/studio",
        role: authRoles.seo,
      },
    ],
  },
  {
    icon: <ClassIcon style={{ fontSize: "19px" }} />,
    name: "Categories",
    role: authRoles.editor,
    children: [
      {
        name: "Categories",
        path: "/categories",
        iconText: "B",
        role: authRoles.editor,
      },
      {
        name: "SubCategories",
        path: "/subcategories",
        iconText: "E",
        role: authRoles.editor,
      },
    ],
  },
  {
    name: "Courses",
    path: "/courses",
    role: authRoles.seo,
    icon: <ChromeReaderModeIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Retreats",
    path: "/retreats",
    role: authRoles.editor,
    icon: <ChromeReaderModeIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Teacher Trainings",
    path: "/teacher-trainings",
    role: authRoles.editor,
    icon: <ChromeReaderModeIcon style={{ fontSize: "19px" }} />,
  },
  // {
  //   name: "Subscriptions",
  //   path: "/subscriptions",
  //   icon: <SubscriptionsIcon style={{ fontSize: "19px" }} />,
  // },
  {
    name: "Sessions",
    path: "/sessions",
    role: authRoles.editor,
    icon: <BurstModeIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Challenges",
    path: "/challenges",
    role: authRoles.editor,
    icon: <LibraryBooksIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Admins",
    // role: "SA",
    path: "/admins",
    role: authRoles.admin,
    icon: <SupervisorAccountIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Strapi's Admin",
    // role: "SA",
    path: `${process.env.REACT_APP_LANDING_BASE_URL}/admin`,
    type: "extLink",
    role: authRoles.admin,
    icon: <Person style={{ fontSize: "19px" }} />,
  },
  {
    name: "Quotes",
    path: "/quotes",
    role: authRoles.editor,
    icon: <FormatQuoteIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Suggestions",
    path: "/suggestions",
    role: authRoles.editor,
    icon: <ImportContactsIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Contacts",
    path: "/contacts",
    role: authRoles.editor,
    icon: <Contacts style={{ fontSize: "19px" }} />,
  },
  {
    name: "Configurations",
    path: "/configurations",
    role: authRoles.admin,
    icon: <SettingsIcon style={{ fontSize: "19px" }} />,
  },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    role: authRoles.admin,
    icon: <CardMembership style={{ fontSize: "19px" }} />,
  },
  {
    name: "Email Log",
    path: "/email-log",
    role: authRoles.admin,
    icon: <EmailIcon style={{ fontSize: "19px" }} />,
  },

  {
    name: "Stripe Account",
    path: `${process.env.REACT_APP_STRIPE_URL}`,
    type: "extLink",
    role: authRoles.admin,
    icon: <AccountBalance style={{ fontSize: "19px" }} />,
  },
  {
    name: "Google Analytics",
    path: `${process.env.REACT_APP_GOOGLE_ANALYTICS_URL}`,
    type: "extLink",
    role: authRoles.viewer,
    icon: <Assessment style={{ fontSize: "19px" }} />,
  },
];
