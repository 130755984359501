import React from "react";

const configurationsRoutes = [
  {
    path: "/configurations",
    exact: true,
    component: React.lazy(() => import("./index")),
  },
 
];

export default configurationsRoutes;
