// types
import { SHOW_MESSAGE, HIDE_MESSAGE, GO_BACK } from "../actions/types";

// initial state
const initialState = {
  title: null,
  message: null,
  severity: "success",
  show: false,
  goBack: false,
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GO_BACK: {
      return {
        ...state,
        goBack: payload,
      };
    }
    case SHOW_MESSAGE:
      return {
        ...state,
        title: payload.title,
        message: payload.message,
        severity: payload.severity,
        show: true,
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
}
