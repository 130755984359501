import React from "react";
import { authRoles } from "../../auth/authRoles";

const userRoutes = [
  {
    path: "/courses/details/:id",
    exact: true,
    component: React.lazy(() => import("./Details")),
  },
  {
    path: "/courses",
    exact: true,
    component: React.lazy(() => import("./index")),
   // auth: authRoles.admin,
  },
];

export default userRoutes;
